<template>
  <div class="news">
    <div style="height: 80px"></div>
    <banner title="新闻中心" subTitle="News Center" />
    <div class="body-box">
      <div class="c-width cf w1200">
        <div class="l-menu fl">
          <p class="menu-name">{{data[index].title}}</p>
          <p
            v-for="(item, index) in data"
            :key="item.id"
            class="link"
            :class="[item.id == fid ? 'on' : '']"
            @click="selLink({ index }, item.id)"
          >
            {{ item.title }}
          </p>
        </div>
        <div class="r-box fr">
          <div class="r-header cf">
            <h4 class="fl">{{ data[index].title }}</h4>
            <div class="fr path">首页 > 新闻中心 > {{ data[index].title }}</div>
          </div>
          <div class="r-context">
            <div class="cf">
              <div
                class="item cf"
                v-for="(item, index) in data[index].newsList"
                :key="item.id"
                @click="toNewsDetail(index)"
              >
                <div class="date fl">
                    <div class="day">{{item.day}}</div>
                    <div class="time">{{item.time}}</div>
                </div>
                <img class="img fl" :src="item.src" alt="">
                <div class="news-info fl">
                    <p class="name">{{item.name}}</p>
                    <p class="hint">{{item.hint}}</p>
                    <p class="more">了解详情></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import data from "../data/data";
import banner from "@/components/banner";
export default {
  components: {
    banner,
  },
  data() {
    return {
      data: data,
      fid: 1,
      index: 0,
      itemIndex:0
    };
  },
  methods: {
    selLink: function (index, fid) {
        console.log(index)
        // if(index.index == 1){
        //    this.$router.push('/production-base')
        //     return;
        // }
      this.index = index.index;
      this.fid = fid;
    },
    toNewsDetail:function(index){
      console.log(index)
      // return;
      this.$router.push({path:'/news-detail',query:{
        index:this.index,
        itemIndex:index,
      }});
    }
  },
};
</script>
<style scoped>
@import "../style.css";
</style>